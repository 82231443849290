body {
  margin: 0;
  color: white;
  font-family: 'Source Code Pro', monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.css-1nhje1t {
  display: none!important;
}
.css-14k66z6 {
  display: none!important;
}
